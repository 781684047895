import React, { useEffect } from 'react';
import { SvgStyled } from './styled';

interface AvatarProps {
  title: string;
  titleId: string;
}

const Avatar = ({ title, titleId, ...props }: AvatarProps) => {
  useEffect(() => {
    const handleScroll = () => {
      const avatar = document.getElementById('avatar');

      if (avatar === null) return false;
      const { top, height } = avatar.getBoundingClientRect();
      const { innerHeight } = window;
      const threshold = (innerHeight * 20) / 100;
      const startLookUp = top < threshold;
      const startLookDown = top + height > innerHeight - threshold;

      let eyeBall = document.querySelector('.eyeball'),
        pupil = document.querySelector('.pupil') as HTMLElement;

      if (eyeBall && pupil) {
        const getCapedCoordonate = () => {
          if (!startLookUp && !startLookDown) return 0;
          if (startLookUp) return 3;
          if (startLookDown) return -3;
        };

        const Y = getCapedCoordonate();

        pupil.style.transform = `translateY(${Y + 'px'})`;
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <SvgStyled
      width={264}
      height={264}
      fill="none"
      id="avatar"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <mask id="prefix__a" maskUnits="userSpaceOnUse" x={12} y={24} width={240} height={240}>
        <path
          d="M132 264c66.274 0 120-53.726 120-120S198.274 24 132 24 12 77.726 12 144s53.726 120 120 120z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M132 264c66.274 0 120-53.726 120-120S198.274 24 132 24 12 77.726 12 144s53.726 120 120 120z"
          fill="url(#prefix__paint6_linear)"
        />
        <path d="M252 24H12v240h240V24z" fill="url(#prefix__paint7_linear)" />
      </g>
      <mask id="prefix__b" maskUnits="userSpaceOnUse" x={0} y={0} width={264} height={264}>
        <path
          d="M12 150.857C12 213.344 65.726 264 132 264s120-50.656 120-113.143h12V0H0v150.857h12z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <mask id="prefix__c" maskUnits="userSpaceOnUse" x={32} y={20} width={200} height={244}>
          <path
            d="M156 164.611V183h4c39.765 0 72 32.235 72 72v9H32v-9c0-39.765 32.236-72 72-72h4v-18.389c-17.237-8.189-29.628-24.924-31.695-44.73C70.48 119.058 66 114.052 66 108V94c0-5.946 4.325-10.882 10-11.834V76c0-30.928 25.072-56 56-56s56 25.072 56 56v6.166c5.675.952 10 5.888 10 11.834v14c0 6.052-4.48 11.058-10.305 11.881-2.067 19.806-14.458 36.541-31.695 44.73z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#prefix__c)">
          <path
            d="M156 164.611V183h4c39.765 0 72 32.235 72 72v9H32v-9c0-39.765 32.236-72 72-72h4v-18.389c-17.237-8.189-29.628-24.924-31.695-44.73C70.48 119.058 66 114.052 66 108V94c0-5.946 4.325-10.882 10-11.834V76c0-30.928 25.072-56 56-56s56 25.072 56 56v6.166c5.675.952 10 5.888 10 11.834v14c0 6.052-4.48 11.058-10.305 11.881-2.067 19.806-14.458 36.541-31.695 44.73z"
            fill="#D0C6AC"
          />
          <path d="M264 20H0v244h264V20z" fill="#EDB98A" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 99v23c0 30.928-25.072 56-56 56s-56-25.072-56-56V99v15c0 30.928 25.072 56 56 56s56-25.072 56-56V99z"
            fill="#000"
            fillOpacity={0.1}
          />
        </g>
        <mask id="prefix__d" maskUnits="userSpaceOnUse" x={32} y={167} width={200} height={97}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108 167.071c-17.919 2.005-31.72 7.481-31.996 21.574C50.146 199.568 32 225.165 32 254.999V264h200v-9.001c0-29.834-18.146-55.431-44.004-66.354-.276-14.093-14.077-19.569-31.996-21.574V186c0 13.255-10.745 24-24 24s-24-10.745-24-24v-18.929z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#prefix__d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108 167.071c-17.919 2.005-31.72 7.481-31.996 21.574C50.146 199.568 32 225.165 32 254.999V264h200v-9.001c0-29.834-18.146-55.431-44.004-66.354-.276-14.093-14.077-19.569-31.996-21.574V186c0 13.255-10.745 24-24 24s-24-10.745-24-24v-18.929z"
            fill="#B7C1DB"
          />
          <path d="M264 154H0v110h264V154z" className="sweat" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M102 215.739V264h-7v-51.85a64.465 64.465 0 007 3.589zm67-3.589v40.35a3.5 3.5 0 11-7 0v-36.761a64.501 64.501 0 007-3.589z"
            fill="#F4F4F4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M90.96 166.724c-15.05 2.847-25.46 8.519-25.46 19.584C65.5 206.02 98.538 222 132 222s66.5-15.98 66.5-35.692c0-11.065-10.409-16.737-25.46-19.584 9.085 3.35 14.96 8.982 14.96 18.353C188 205.469 160.179 222 132 222s-56-16.531-56-36.923c0-9.371 5.875-15.003 14.96-18.353z"
            fill="#000"
            fillOpacity={0.16}
          />
        </g>
        <mask id="prefix__e" maskUnits="userSpaceOnUse" x={113} y={131} width={38} height={19}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.118 133.128C114.175 142.62 122.226 150 132 150c9.804 0 17.874-7.426 18.892-16.96.081-.767-.775-2.04-1.849-2.04h-33.956c-1.081 0-2.075 1.178-1.969 2.128z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#prefix__e)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.118 133.128C114.175 142.62 122.226 150 132 150c9.804 0 17.874-7.426 18.892-16.96.081-.767-.775-2.04-1.849-2.04h-33.956c-1.081 0-2.075 1.178-1.969 2.128z"
            fill="#000"
            fillOpacity={0.7}
          />
          <path
            d="M143 120h-21a5 5 0 00-5 5v6a5 5 0 005 5h21a5 5 0 005-5v-6a5 5 0 00-5-5z"
            fill="#fff"
          />
          <path
            d="M127 164c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
            fill="#FF4F6D"
          />
          <path
            d="M137 164c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
            fill="#FF4F6D"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120 114c0 4.418 5.373 8 12 8s12-3.582 12-8"
          fill="#000"
          fillOpacity={0.16}
        />
        <path
          d="M106 108c7.732 0 14-5.373 14-12s-6.268-12-14-12-14 5.373-14 12 6.268 12 14 12zm52 0c7.732 0 14-5.373 14-12s-6.268-12-14-12-14 5.373-14 12 6.268 12 14 12z"
          fill="#fff"
          className="eyeball"
        />
        <path
          d="M106 102a6 6 0 100-12 6 6 0 000 12zm52 0a6 6 0 100-12 6 6 0 000 12z"
          // fill="#000"
          fillOpacity={0.7}
          className="pupil"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.766 67.578l.911-.402c5.242-2.081 13.188-1.209 18.046 1.123.567.272.179 1.153-.402 1.108-14.919-1.151-24.963 8.146-28.375 14.44-.101.187-.407.208-.483.034-2.307-5.319 4.45-13.985 10.303-16.303zm66.468 0l-.911-.402c-5.242-2.081-13.188-1.209-18.046 1.123-.567.272-.179 1.153.402 1.108 14.919-1.151 24.963 8.146 28.375 14.44.101.187.407.208.482.034 2.308-5.319-4.449-13.985-10.302-16.303z"
          fill="#000"
          fillOpacity={0.6}
        />
        <mask id="prefix__f" maskUnits="userSpaceOnUse" x={0} y={0} width={264} height={264}>
          <path d="M264 0H0v264h264V0z" fill="#fff" />
        </mask>
        <g mask="url(#prefix__f)">
          <mask id="prefix__g" maskUnits="userSpaceOnUse" x={75} y={82} width={114} height={89}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M149.428 154.169c-2.513 2.294-5.191 3.325-8.575 2.604-.582-.124-2.957-4.538-8.853-4.538-5.897 0-8.271 4.414-8.853 4.538-3.385.721-6.062-.31-8.576-2.604-4.725-4.313-8.654-10.261-6.293-16.75 1.23-3.382 3.232-7.095 6.873-8.173 3.887-1.15 9.346-.002 13.264-.788 1.269-.254 2.656-.707 3.585-1.458.929.751 2.316 1.204 3.585 1.458 3.918.786 9.376-.362 13.264.788 3.641 1.078 5.642 4.791 6.873 8.173 2.361 6.489-1.568 12.437-6.294 16.75zM188.081 82c-3.41 8.4-2.093 18.858-2.724 27.676-.513 7.167-2.02 17.91-8.384 22.539-3.255 2.367-9.179 6.345-13.431 5.235-2.927-.764-3.24-9.16-7.087-12.303-4.363-3.565-9.812-5.131-15.306-4.889-2.37.104-7.165.079-9.149 1.902-1.984-1.823-6.778-1.798-9.149-1.902-5.494-.242-10.943 1.324-15.306 4.889-3.847 3.143-4.159 11.539-7.087 12.303-4.252 1.11-10.176-2.868-13.431-5.235-6.365-4.629-7.87-15.372-8.384-22.539-.63-8.818.686-19.276-2.724-27.676-1.66 0-.565 16.129-.565 16.129v20.356c.032 15.288 9.581 38.17 30.754 46.908C111.286 167.53 123.015 171 132 171s20.714-3.14 25.892-5.277c21.173-8.737 30.722-31.95 30.754-47.238V98.129S189.74 82 188.081 82z"
              fill="#fff"
            />
          </mask>
          <g mask="url(#prefix__g)" fill="#8C745E">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M149.428 154.169c-2.513 2.294-5.191 3.325-8.575 2.604-.582-.124-2.957-4.538-8.853-4.538-5.897 0-8.271 4.414-8.853 4.538-3.385.721-6.062-.31-8.576-2.604-4.725-4.313-8.654-10.261-6.293-16.75 1.23-3.382 3.232-7.095 6.873-8.173 3.887-1.15 9.346-.002 13.264-.788 1.269-.254 2.656-.707 3.585-1.458.929.751 2.316 1.204 3.585 1.458 3.918.786 9.376-.362 13.264.788 3.641 1.078 5.642 4.791 6.873 8.173 2.361 6.489-1.568 12.437-6.294 16.75zM188.081 82c-3.41 8.4-2.093 18.858-2.724 27.676-.513 7.167-2.02 17.91-8.384 22.539-3.255 2.367-9.179 6.345-13.431 5.235-2.927-.764-3.24-9.16-7.087-12.303-4.363-3.565-9.812-5.131-15.306-4.889-2.37.104-7.165.079-9.149 1.902-1.984-1.823-6.778-1.798-9.149-1.902-5.494-.242-10.943 1.324-15.306 4.889-3.847 3.143-4.159 11.539-7.087 12.303-4.252 1.11-10.176-2.868-13.431-5.235-6.365-4.629-7.87-15.372-8.384-22.539-.63-8.818.686-19.276-2.724-27.676-1.66 0-.565 16.129-.565 16.129v20.356c.032 15.288 9.581 38.17 30.754 46.908C111.286 167.53 123.015 171 132 171s20.714-3.14 25.892-5.277c21.173-8.737 30.722-31.95 30.754-47.238V98.129S189.74 82 188.081 82z"
            />
            <path d="M280 56H16v244h264V56z" />
          </g>
          <mask id="prefix__h" maskUnits="userSpaceOnUse" x={73} y={1} width={117} height={82}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M179.15 23.92c-2.76-2.82-5.964-5.213-9.081-7.614-.687-.529-1.386-1.045-2.058-1.594-.153-.125-1.719-1.246-1.906-1.659-.451-.993-.19-.22-.128-1.404.079-1.498 3.134-5.73.854-6.7-1.003-.427-2.791.709-3.753 1.084a59.539 59.539 0 01-5.731 1.9c.932-1.857 2.708-5.573-.631-4.579-2.602.775-5.026 2.768-7.64 3.705.865-1.417 4.324-5.811 1.198-6.057-.972-.076-3.803 1.748-4.85 2.138-3.137 1.165-6.341 1.92-9.634 2.513-11.198 2.018-24.293 1.442-34.653 6.54-7.987 3.93-15.874 10.029-20.489 17.794-4.447 7.486-6.11 15.677-7.041 24.254-.683 6.295-.739 12.802-.42 19.119.105 2.07.338 11.61 3.345 8.721 1.498-1.44 1.487-7.253 1.864-9.22.751-3.916 1.474-7.848 2.726-11.638 2.206-6.68 4.809-13.793 10.305-18.393 3.527-2.952 6.004-6.941 9.379-9.919 1.516-1.337.359-1.198 2.797-1.022 1.638.117 3.282.162 4.923.205 3.796.099 7.598.074 11.395.087 7.647.028 15.258.136 22.898-.265 3.395-.177 6.799-.274 10.185-.588 1.891-.175 5.247-1.387 6.804-.461 1.425.847 2.905 3.615 3.928 4.748 2.418 2.679 5.3 4.724 8.126 6.92 5.895 4.58 8.87 10.332 10.661 17.488 1.783 7.13 1.283 13.745 3.49 20.762.389 1.234 1.416 3.36 2.682 1.454.235-.354.175-2.3.175-3.42 0-4.52 1.144-7.91 1.13-12.46-.056-13.832-.504-31.868-10.85-42.439z"
              fill="#fff"
            />
          </mask>
          <g mask="url(#prefix__h)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M179.15 23.92c-2.76-2.82-5.964-5.213-9.081-7.614-.687-.529-1.386-1.045-2.058-1.594-.153-.125-1.719-1.246-1.906-1.659-.451-.993-.19-.22-.128-1.404.079-1.498 3.134-5.73.854-6.7-1.003-.427-2.791.709-3.753 1.084a59.539 59.539 0 01-5.731 1.9c.932-1.857 2.708-5.573-.631-4.579-2.602.775-5.026 2.768-7.64 3.705.865-1.417 4.324-5.811 1.198-6.057-.972-.076-3.803 1.748-4.85 2.138-3.137 1.165-6.341 1.92-9.634 2.513-11.198 2.018-24.293 1.442-34.653 6.54-7.987 3.93-15.874 10.029-20.489 17.794-4.447 7.486-6.11 15.677-7.041 24.254-.683 6.295-.739 12.802-.42 19.119.105 2.07.338 11.61 3.345 8.721 1.498-1.44 1.487-7.253 1.864-9.22.751-3.916 1.474-7.848 2.726-11.638 2.206-6.68 4.809-13.793 10.305-18.393 3.527-2.952 6.004-6.941 9.379-9.919 1.516-1.337.359-1.198 2.797-1.022 1.638.117 3.282.162 4.923.205 3.796.099 7.598.074 11.395.087 7.647.028 15.258.136 22.898-.265 3.395-.177 6.799-.274 10.185-.588 1.891-.175 5.247-1.387 6.804-.461 1.425.847 2.905 3.615 3.928 4.748 2.418 2.679 5.3 4.724 8.126 6.92 5.895 4.58 8.87 10.332 10.661 17.488 1.783 7.13 1.283 13.745 3.49 20.762.389 1.234 1.416 3.36 2.682 1.454.235-.354.175-2.3.175-3.42 0-4.52 1.144-7.91 1.13-12.46-.056-13.832-.504-31.868-10.85-42.439z"
              fill="#1F3140"
            />
            <path d="M263-16H-1v280h264V-16z" fill="#4A312C" />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="prefix__paint6_linear"
          x1={131.962}
          y1={263.724}
          x2={131.962}
          y2={24.734}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear"
          x1={131.962}
          y1={263.724}
          x2={131.962}
          y2={24.734}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
      </defs>
    </SvgStyled>
  );
};

export default Avatar;
