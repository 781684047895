import * as React from 'react';
import { ISvg } from '../../definitions/interfaces';

const SvgComponent = ({ title, ...props }: ISvg) => {
  return (
    <svg width={1706.7} height={1706.7} viewBox="0 0 1280 1280" aria-labelledby={title} {...props}>
      {title ? <title>{title}</title> : null}
      <path d="M603 1c-41 3-91 10-97 15-6 4-6 4-6 52v44l-2 1-19 6a526 526 0 00-106 45l-6 4-31-31-34-32h-13l-41 30a731 731 0 00-144 158c-2 7-1 8 33 42l31 32-4 6a682 682 0 00-45 106l-6 19-1 2H68c-49 0-48 0-52 6-4 5-10 43-14 80-2 25-2 83 0 109 4 36 10 74 14 79 4 6 3 6 52 6h44l1 2 6 20a526 526 0 0045 106l4 6-31 31-32 33v13l30 42a731 731 0 00158 143c7 2 8 1 42-32l32-32 6 4a682 682 0 00125 51l2 1v44c0 49 0 48 6 52 5 4 43 10 80 14 25 2 83 2 109 0 36-4 74-10 79-14 6-4 6-3 6-52v-44l2-1 20-6a526 526 0 00106-45l6-4 31 31 33 32h13a731 731 0 00185-187c2-8 1-9-32-43l-32-31 4-6a682 682 0 0051-126l1-2h44c49 0 48 0 52-6 4-5 10-43 14-79 2-26 2-84 0-109-4-37-10-75-14-80-4-6-3-6-52-6h-44l-1-2-6-20a526 526 0 00-45-105l-4-6 31-31 32-34v-13a731 731 0 00-187-185c-8-2-9-1-43 32l-31 32-6-4a682 682 0 00-106-45l-20-6-2-1V68c0-49 0-48-6-52-5-4-43-10-78-14-20-1-74-2-93-1zm66 344a297 297 0 11-59 590 297 297 0 0159-590z" />
    </svg>
  );
};

export default SvgComponent;
